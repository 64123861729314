const firebaseConfig = {
  apiKey: "AIzaSyCHnMWHFV2hODe-rXPEFU4BI7DS0az3xbs",
  authDomain: "morgantomasini-2edbb.firebaseapp.com",
  databaseURL: "https://morgantomasini-2edbb.firebaseio.com",
  projectId: "morgantomasini-2edbb",
  storageBucket: "morgantomasini-2edbb.appspot.com",
  messagingSenderId: "592805137596",
  appId: "1:592805137596:web:888e94e8514b54d7e0d4b9",
  measurementId: "G-LTZK8M6Q30"
};

export default firebaseConfig;
