import React from "react";
import { useThemeValue } from "../../providers/theme";
import withWidth, { WithWidthProps } from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

const ShortTypedNameComponent: React.FunctionComponent<WithWidthProps> = ({
  width = "md"
  // scaleFactor = 1,
}) => {
  const breakpointsSizeMap: { [key in Breakpoint]: number | string } = {
    xs: ".7rem",
    sm: "1.1rem",
    md: "1.2rem",
    lg: "1.3rem",
    xl: "1.3rem"
  };
  const { themeState } = useThemeValue();
  const fillColor = themeState.theme.palette.text.primary;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={breakpointsSizeMap[width]}
      viewBox="0 0 61.01 9.25"
    >
      <path
        d="M1.05,10.61l-.36,0A1.93,1.93,0,0,1,.36,10a4.54,4.54,0,0,1-.23-.74A4.22,4.22,0,0,1,.06,8.5,1.94,1.94,0,0,1,.2,7.8l.11-.28c.07-.18.17-.4.29-.68s.25-.59.4-.93.29-.67.44-1,.29-.63.43-.9a4.92,4.92,0,0,1,.36-.62l.19-.32.17-.28.24-.39a2,2,0,0,1,.25-.33,1.48,1.48,0,0,1,.3-.26,1.57,1.57,0,0,1,.39-.19L4,1.74a1,1,0,0,1,.19.16l0,.16a.19.19,0,0,0,.17.14l.26.08a.85.85,0,0,0,.24.05L5,2.47a.6.6,0,0,0,.09.13,5.79,5.79,0,0,1,.38.66.83.83,0,0,1,0,.69v.13a.24.24,0,0,1,.06.15.83.83,0,0,1,0,.2c0,.06,0,.13,0,.2a1.13,1.13,0,0,0,0,.25L5.61,5A.87.87,0,0,0,6.4,5l.35-.21A2,2,0,0,1,7,4.59a1,1,0,0,1,.22-.08l.45-.41a5.2,5.2,0,0,1,.43-.37c.28-.23.55-.47.82-.74a2.91,2.91,0,0,0,.63-1,.48.48,0,0,1,.29-.09,1,1,0,0,1,.33.07l.33.14.06,0,.07,0a.33.33,0,0,0,.1.24l.08.06,0,0,.16.11a.27.27,0,0,1,.1.13,1,1,0,0,0,.35.49.7.7,0,0,0,0,.25c0,.08,0,.16.08.24a.36.36,0,0,1,0,.09l0,.1-.25,0,0,.26a.89.89,0,0,1,0,.45,1.61,1.61,0,0,1-.14.38c0,.24,0,.42,0,.56s0,.26,0,.38a1.87,1.87,0,0,1-.08.34c0,.1-.08.21-.13.33a4.3,4.3,0,0,1-.21,1.63q-.26.74-.54,1.38l-.07.15a.53.53,0,0,0-.06.14A1.34,1.34,0,0,0,9.9,10l-.2.1a1.57,1.57,0,0,1-.25.14.86.86,0,0,1-.27,0A1.66,1.66,0,0,1,8.6,8.87,9.1,9.1,0,0,1,9,6.3a.15.15,0,0,0,.07-.17V6H8.87s0,0-.07.11c-.25.18-.52.36-.81.55s-.6.37-.91.53a6.71,6.71,0,0,1-1,.4,3.2,3.2,0,0,1-.94.16,1.9,1.9,0,0,1-1.77-1.2L3.3,6.34l-.11.19a1.66,1.66,0,0,0-.13.23C3,6.87,3,7,2.89,7.15a3.53,3.53,0,0,0-.15.46A.86.86,0,0,0,2.7,8a1.94,1.94,0,0,0-.28.29,1,1,0,0,0-.19.37.23.23,0,0,0,0,.11.56.56,0,0,0,0,.12A.16.16,0,0,1,2.2,9a.27.27,0,0,1-.08.07L2,9.22a.71.71,0,0,0-.1.14.83.83,0,0,0,0,.15.44.44,0,0,1,0,.13.26.26,0,0,1,0,.18,3.69,3.69,0,0,1-.2.4,1.07,1.07,0,0,1-.17.24.38.38,0,0,1-.17.12A.66.66,0,0,1,1.05,10.61Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M18.52,6.93a.82.82,0,0,0,0,.27,1.25,1.25,0,0,0,.09.25c0,.08.07.16.1.25A2.5,2.5,0,0,1,18.8,8a2,2,0,0,1,0,.35,2.34,2.34,0,0,1-.09.38,1.24,1.24,0,0,1-.15.33.49.49,0,0,1-.22.21,1.06,1.06,0,0,1-.4.33,2.44,2.44,0,0,0-.43.28.74.74,0,0,1-.29.12L16.9,10l-.31.15a3,3,0,0,1-.36.14,2.49,2.49,0,0,1-.37.09,1.32,1.32,0,0,1-.33,0,3.15,3.15,0,0,1-.69.17,2.4,2.4,0,0,1-.78,0,2,2,0,0,1-.78,0,1.67,1.67,0,0,1-.63-.3,2.24,2.24,0,0,1-.48-.49,2.52,2.52,0,0,1-.33-.6,3.08,3.08,0,0,1,0-1.22,4.2,4.2,0,0,1,.41-1,4.72,4.72,0,0,1,.6-.86l.66-.77a.11.11,0,0,0,.11,0,.27.27,0,0,0,.07-.08l.08-.1a.15.15,0,0,1,.1-.06.53.53,0,0,1,.24-.27.55.55,0,0,0,.25-.25.48.48,0,0,0,.18-.07l.13-.1.14-.12A.39.39,0,0,1,15,4.15V4a.77.77,0,0,0,.31-.22l.24-.3h.17l.2,0a1.08,1.08,0,0,1,.17-.25,1.12,1.12,0,0,1,.22-.17,1.58,1.58,0,0,0,.31.09.54.54,0,0,1,.3.15.3.3,0,0,0,.07.18.71.71,0,0,0,.13.12l.14.11a.39.39,0,0,1,.09.17.06.06,0,0,0,.06,0s0,0,.06,0a.59.59,0,0,0,.09.22.61.61,0,0,1,.09.21.85.85,0,0,0-.09.24.28.28,0,0,1-.14.18s0,.08,0,.09.07,0,.07.06a.17.17,0,0,1,0,.15A8.06,8.06,0,0,1,18,6,8.12,8.12,0,0,0,18.52,6.93ZM17.05,8a.53.53,0,0,1,.12-.21.44.44,0,0,0,.09-.25.59.59,0,0,1-.07-.14.81.81,0,0,1,0-.17,6.06,6.06,0,0,1-.45-.78,4.84,4.84,0,0,0-.42-.78.22.22,0,0,1-.08.09s-.08,0-.13,0a.2.2,0,0,1-.08.13.2.2,0,0,1-.13,0l-.15,0-.13-.07-.68.52a3.46,3.46,0,0,0-.61.6l-.06,0s0,0-.07,0a2,2,0,0,1-.23.4l-.26.37a1.94,1.94,0,0,0-.22.4,1.22,1.22,0,0,0-.11.49.87.87,0,0,0,.47.2,2.61,2.61,0,0,0,.56,0A3.43,3.43,0,0,0,15,8.79a3.14,3.14,0,0,1,.51,0,3.28,3.28,0,0,0,.84-.31Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M21.12,9.22a6,6,0,0,1-.23.71,2.45,2.45,0,0,1-.3.5.26.26,0,0,0-.21,0,.69.69,0,0,1-.13.11.19.19,0,0,1-.15,0L20,10.52l-.09-.06a.35.35,0,0,0-.16,0c0-.06,0-.12-.05-.18s-.08-.07-.16-.05a.47.47,0,0,0,0-.16l0-.12,0-.11a.31.31,0,0,1,0-.13s-.07,0-.09-.08a1.3,1.3,0,0,0-.08-.17A3.91,3.91,0,0,1,19.42,9a1.9,1.9,0,0,1,.09-.36c0-.11.06-.22.08-.32a.5.5,0,0,0,0-.34s.05,0,.07,0a.49.49,0,0,0,0-.14,1.1,1.1,0,0,0,0-.18.46.46,0,0,0,0-.14,5.1,5.1,0,0,0,.2-.63c.05-.22.1-.43.14-.64s.09-.42.13-.62a3.07,3.07,0,0,1,.19-.56.5.5,0,0,1,.29,0,.29.29,0,0,1,.19.13,2.94,2.94,0,0,0,.54-.57L21.82,4a3.24,3.24,0,0,1,.55-.56,1.3,1.3,0,0,1,.75-.29l.52.35a1.57,1.57,0,0,1,.48.48l.16.1.15.11v.14a1,1,0,0,1,.23.3,3,3,0,0,1,.16.36l.14.39c.05.13.1.25.16.37a.44.44,0,0,0,0,.1.31.31,0,0,0,0,.08l0,.1a.28.28,0,0,1,0,.13,1.33,1.33,0,0,1-.34.83,2.3,2.3,0,0,1-.72.52,6,6,0,0,1-.9.35,7.33,7.33,0,0,0-.87.3,0,0,0,0,0,.05.05s0,0,0,.06l.45.28a1.94,1.94,0,0,0,.31.17l.32.13.47.12.48.11.31.07.28.05.42,0a.94.94,0,0,1,.18.25.91.91,0,0,1,.1.29,1,1,0,0,1,0,.29.33.33,0,0,1-.08.21s0,0,.05,0h.09a.63.63,0,0,0-.12.11l-.09.15-.11.12a.24.24,0,0,1-.16.06,1.53,1.53,0,0,1-.91,0,9.48,9.48,0,0,1-1.4-.45l-.68-.29a3.87,3.87,0,0,1-.46-.24,2.48,2.48,0,0,1-.35-.27Zm1.67-4a1.88,1.88,0,0,1-.28.34c-.11.1-.21.21-.31.31a2.36,2.36,0,0,0-.26.36,1.14,1.14,0,0,0-.17.45,3.67,3.67,0,0,0,.86-.23l.84-.35a1,1,0,0,0-.25-.58A1.06,1.06,0,0,0,22.79,5.2Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M34,8.33a2,2,0,0,1-.74.78,5.09,5.09,0,0,1-1,.46c-.37.12-.76.23-1.16.32a9.32,9.32,0,0,0-1.17.36l-.28,0-.24,0-.21,0-.24,0a0,0,0,0,0-.06,0s0,0,0,0l-.36,0h-.26a1,1,0,0,0-.29,0l-.26,0a1.91,1.91,0,0,1-1.38-.63,2.68,2.68,0,0,1-.56-1.61s0-.07,0-.07l-.06,0a.6.6,0,0,1,0-.2.46.46,0,0,0,0-.21,7.81,7.81,0,0,1,.69-1.2,4.55,4.55,0,0,1,.93-1,1.85,1.85,0,0,0,.14-.14,1.34,1.34,0,0,1,.11-.14.34.34,0,0,1,.12-.12.49.49,0,0,1,.18-.08,2.87,2.87,0,0,1,.58-.51c.23-.16.47-.31.72-.46L30,3.72a3.73,3.73,0,0,0,.67-.49c.09,0,.15.06.19,0a.15.15,0,0,1,.13,0,.51.51,0,0,0,.23.25l.29.14a1.67,1.67,0,0,1,.28.18.84.84,0,0,1,.2.28c-.05,0-.07,0-.07.06l0,.1a.22.22,0,0,1,0,.11s0,.05-.09.05a.09.09,0,0,0,.08.07.05.05,0,0,1,.06.06.12.12,0,0,1,0,.09.13.13,0,0,0,0,.12,5.06,5.06,0,0,1-.52.29l-.6.3-.65.34a5.12,5.12,0,0,0-.65.42c-.21.16-.42.33-.64.52s-.43.39-.63.6-.4.43-.58.65a6.19,6.19,0,0,0-.46.67.28.28,0,0,0,.06.08.36.36,0,0,1,.05.09,4.09,4.09,0,0,0,.7,0,2.05,2.05,0,0,1,.65,0,.1.1,0,0,0,0-.07.11.11,0,0,1,.06-.07A9,9,0,0,0,30,8.42l1.17-.31L31,8l-.15,0s0-.07,0-.1A.31.31,0,0,0,31,7.8a.87.87,0,0,1-.27-.23.9.9,0,0,1-.12-.37.18.18,0,0,0,.07-.15.13.13,0,0,0-.07-.12h-.06s0,0,0,0,0,0,0,0h-.06a.48.48,0,0,1,.05-.18.77.77,0,0,0,.07-.16l.8-.54.32.2.39.22.43.23.4.21a1,1,0,0,1,.29.19l.25.24.24.24a1,1,0,0,0,.29.2.41.41,0,0,0,0,.25A.55.55,0,0,1,34,8.33Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M39.75,9.46a2.21,2.21,0,0,1-.21.4,1.34,1.34,0,0,1-.31.36,1.62,1.62,0,0,1-.41.27,1.21,1.21,0,0,1-.46.12.65.65,0,0,1-.21-.08L38,10.45l-.07,0,0,0-.14-.06,0-.15a.21.21,0,0,0,0-.14.88.88,0,0,0-.14-.15l-.07-.07a.19.19,0,0,1-.06-.07l-.2-.22.31-.09a.71.71,0,0,0,.4-.21.64.64,0,0,0,.07-.43.87.87,0,0,0-.39,0l-.32.08L37,9a2.29,2.29,0,0,1-.47,0h-.36l-.11,0c0-.08,0-.13,0-.14l0,0a1.11,1.11,0,0,1-.12-.16.35.35,0,0,1,0-.25.63.63,0,0,1-.07-.22,1.82,1.82,0,0,0-.11.3l0,.11-.12,0a.06.06,0,0,0,0,.05.25.25,0,0,0,0,.07l0,.19s0,0,0,0l-.05,0a.83.83,0,0,1-.06.21,1.59,1.59,0,0,1-.07.2c0,.1-.07.2-.1.3A1.33,1.33,0,0,0,35,10l0,.13a.33.33,0,0,1-.13.11l-.14.08-.07,0-.06,0,0,.13-.14,0-.18,0H34l-.2-.17-.16-.13a.51.51,0,0,1-.14-.34.26.26,0,0,1,0-.11.24.24,0,0,0,0-.07l-.15-.14.13-.11v-.2c.15-.4.32-.78.49-1.15L34.43,7l.18-.38.18-.37s0,0,0-.08a.43.43,0,0,1,0-.11A3.9,3.9,0,0,0,35,5.71c0-.1.1-.21.16-.32L35.37,5a3.52,3.52,0,0,1,.25-.38l.19-.08.08,0a.22.22,0,0,1,.11,0l.18,0a1.69,1.69,0,0,0,.57-.43,5.65,5.65,0,0,0,.5-.66l.12-.16.15.09a.54.54,0,0,0,.17.07l.22.06.4.11a.59.59,0,0,1,.3.17A1.66,1.66,0,0,1,38.7,4a.58.58,0,0,0,0,.19c0,.13.05.27.06.4s0,.28.05.44.07.27.1.4.05.26.07.39.08.37.12.54a3.27,3.27,0,0,0,.15.5h.12l.12,0,.15,0,.13,0,0,.12s0,.08.12.12a.49.49,0,0,1,.22.06l.13.11a.2.2,0,0,0,.1.07l.17.07-.09.18a1.4,1.4,0,0,1-.34.4,2,2,0,0,1-.48.33s0,.09,0,.13a.78.78,0,0,0,0,.14,2.7,2.7,0,0,1,.06.41,1.55,1.55,0,0,1,0,.43Zm-3.39-2,.64,0,.64-.1c0-.24-.11-.48-.18-.74s-.12-.5-.19-.75l-.07.12a4.31,4.31,0,0,0-.46.55,6.54,6.54,0,0,0-.36.57l-.15.21a2.25,2.25,0,0,0-.12.21h.25Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M42.93,8.31a.22.22,0,0,0-.16.12,1.8,1.8,0,0,0-.08.21l-.09.19a.12.12,0,0,1-.12.09.38.38,0,0,1-.06.16l-.1.15a.46.46,0,0,0-.07.16.32.32,0,0,0,0,.21.31.31,0,0,0-.12.2,2,2,0,0,1,0,.24.42.42,0,0,1-.1.2.28.28,0,0,1-.26.07s0,0,0,.06,0,0,0,.08a.31.31,0,0,0-.2,0,.25.25,0,0,1-.16,0,.82.82,0,0,1-.37-.24,2.26,2.26,0,0,1-.25-.4,4.14,4.14,0,0,1-.2-.43c-.06-.15-.12-.28-.17-.39a.53.53,0,0,0,0-.25.58.58,0,0,0-.08-.21c.38-.81.8-1.68,1.25-2.6s.88-1.83,1.29-2.73a5.05,5.05,0,0,1,.53.24,2.33,2.33,0,0,1,.48.31,1.74,1.74,0,0,1,.37.4,1.24,1.24,0,0,1,.2.51s0,.07,0,.06,0,0-.05,0a.21.21,0,0,1,.05.09v0s0,0,0,.07a.37.37,0,0,0,0,.11.08.08,0,0,0-.07,0s0,0-.07,0a.44.44,0,0,1,0,.21.53.53,0,0,1-.05.15.35.35,0,0,0-.06.14.25.25,0,0,0,0,.19.06.06,0,0,0-.05,0s0,0,0,0l0,0h-.06a2.19,2.19,0,0,1,0,.51c0,.17,0,.35,0,.52s0,.36,0,.55a2.54,2.54,0,0,0,.08.6,2.05,2.05,0,0,0,.67-.64,3.26,3.26,0,0,0,.41-.86,5.46,5.46,0,0,0,.24-1c.06-.36.12-.74.18-1.12a.76.76,0,0,0,.16-.26,3,3,0,0,0,.11-.32L46,3.51a1.43,1.43,0,0,1,.12-.31.57.57,0,0,1,.3.11,2.11,2.11,0,0,1,.23.19l.22.19a.76.76,0,0,0,.29.11c.1.13.18.26.26.38a1,1,0,0,1,.15.4.67.67,0,0,0-.09.21L47.45,5a1,1,0,0,1-.09.21.46.46,0,0,1-.17.17A4.1,4.1,0,0,1,47.1,6c0,.17-.07.33-.1.5a3.86,3.86,0,0,0-.09.51,3.58,3.58,0,0,0,0,.58c-.07.18-.13.37-.2.57s-.13.39-.17.55a1.28,1.28,0,0,0-.29.37c-.07.14-.14.28-.22.42s0,0-.06,0a.12.12,0,0,0-.08,0,.08.08,0,0,1,0,.07l0,0,0,0a.37.37,0,0,0,0,.09,1,1,0,0,0-.29.2,2.09,2.09,0,0,1-.28.24.74.74,0,0,1-.35.14,1.11,1.11,0,0,1-.53-.08,5,5,0,0,1-.85-.81A3.69,3.69,0,0,1,42.93,8.31Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M52,3.83c0-.06,0-.12,0-.17s-.05-.11-.07-.17a2.11,2.11,0,0,1-.1-.3,1.15,1.15,0,0,1,0-.39.34.34,0,0,1,.06-.17l.07-.09,0-.21h.57l.91,0,1-.08c.36,0,.75-.09,1.16-.11L55.59,2l0-.08.13-.08a.67.67,0,0,1,.37.07A.86.86,0,0,1,56.3,2L56.92,2l.65-.09,1-.11a7.66,7.66,0,0,1,.94-.06h.38l.34,0,.14.11a.83.83,0,0,0,.11.15l.17.14a2.85,2.85,0,0,1,.34.35.81.81,0,0,1,.13.63L61,3.22a.63.63,0,0,1-.12.1l-.12.15h-.13a.42.42,0,0,0-.17,0l-.14,0-.11,0-.13,0h-.36a5.18,5.18,0,0,0-.76.05l-.8.1-.54.07-.47,0q-.1.3-.21.54l-.2.47c-.07.15-.13.31-.2.47a5.52,5.52,0,0,0-.17.53c-.09.15-.17.28-.24.41a2.26,2.26,0,0,0-.15.44,1.34,1.34,0,0,0-.25.43c-.05.16-.1.29-.13.4l0,.18a.27.27,0,0,0-.1.13.26.26,0,0,1-.07.1,1.4,1.4,0,0,1-.09.29,1.12,1.12,0,0,1,0,.13.18.18,0,0,0,0,.1.52.52,0,0,1-.07.2.71.71,0,0,1,0,.14l0,.14a1,1,0,0,0,0,.14.28.28,0,0,0,0,.13l.07.09a.23.23,0,0,1,.06.12l.06,0,.06,0a.38.38,0,0,0,.18.09l.11.14a.75.75,0,0,1-.13.66.83.83,0,0,1-.7.27h-.16l-.1-.11-.17-.08a.27.27,0,0,1-.13-.11l-.06,0a.09.09,0,0,1-.06,0,.68.68,0,0,1-.19-.08l-.13-.07c0-.08,0-.13,0-.16a.85.85,0,0,1-.15-.28,1.92,1.92,0,0,1-.09-.32c0-.09,0-.16-.06-.23A.56.56,0,0,0,53.45,9a4,4,0,0,1,.15-1.38q.18-.56.39-1.08c.08-.19.15-.38.22-.58s.13-.39.19-.59l.13-.13.1-.09a.72.72,0,0,1,.06-.29c0-.1.08-.2.13-.3l.09-.19A.71.71,0,0,0,55,4.2h-.15l-.13,0-.29,0h-.16a.41.41,0,0,1-.17,0h-.45a.48.48,0,0,0-.22,0h-.6l-.21,0a.59.59,0,0,1-.24-.09l-.09-.09c0-.09-.09-.14-.13-.14L52,4Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M60.74,9.05a1.22,1.22,0,0,1,0,.17,1.16,1.16,0,0,0,0,.18,2.09,2.09,0,0,0-.08.23,1.72,1.72,0,0,0-.07.21l0,.11a0,0,0,0,0,0,0v0a.25.25,0,0,1,0,.1l0,.14a.35.35,0,0,1-.14.13l-.16.08-.07,0s-.06,0-.07,0l0,.14-.15,0a1.64,1.64,0,0,1-.34,0h-.14l-.22-.2-.18-.13a.72.72,0,0,1-.13-.24l0-.15a.81.81,0,0,0-.06-.2l-.17-.16.14-.11-.12-.22.21-.15L59,9.1A.85.85,0,0,0,59.11,9l.19-.26.1-.16.15.08a.5.5,0,0,0,.21.07l.28.05.37.09a.56.56,0,0,1,.28.12Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
    </svg>
  );
};

const LongTypedNameComponent: React.FunctionComponent<WithWidthProps> = ({
  width = "md"
}) => {
  const { themeState } = useThemeValue();
  const fillColor = themeState.theme.palette.text.primary;
  const breakpointsSizeMap: { [key in Breakpoint]: number | string } = {
    xs: "1.3rem",
    sm: "1.7rem",
    md: "2rem",
    lg: "2.2rem",
    xl: "2.5rem"
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={breakpointsSizeMap[width]}
      viewBox="0 0 102.09 9.25"
    >
      <path
        d="M1.05,10.61l-.36,0A1.93,1.93,0,0,1,.36,10a4.54,4.54,0,0,1-.23-.74A4.22,4.22,0,0,1,.06,8.5,1.94,1.94,0,0,1,.2,7.8l.11-.28c.07-.18.17-.4.29-.68s.25-.59.4-.93.29-.67.44-1,.29-.63.43-.9a4.92,4.92,0,0,1,.36-.62l.19-.32.17-.28.24-.39a2,2,0,0,1,.25-.33,1.48,1.48,0,0,1,.3-.26,1.57,1.57,0,0,1,.39-.19L4,1.74a1,1,0,0,1,.19.16l0,.16a.19.19,0,0,0,.17.14l.26.08a.85.85,0,0,0,.24.05L5,2.47a.6.6,0,0,0,.09.13,5.79,5.79,0,0,1,.38.66.83.83,0,0,1,0,.69v.13a.24.24,0,0,1,.06.15.83.83,0,0,1,0,.2c0,.06,0,.13,0,.2a1.13,1.13,0,0,0,0,.25L5.61,5A.87.87,0,0,0,6.4,5l.35-.21A2,2,0,0,1,7,4.59a1,1,0,0,1,.22-.08l.45-.41a5.2,5.2,0,0,1,.43-.37c.28-.23.55-.47.82-.74a2.91,2.91,0,0,0,.63-1,.48.48,0,0,1,.29-.09,1,1,0,0,1,.33.07l.33.14.06,0,.07,0a.33.33,0,0,0,.1.24l.08.06,0,0,.16.11a.27.27,0,0,1,.1.13,1,1,0,0,0,.35.49.7.7,0,0,0,0,.25c0,.08,0,.16.08.24a.36.36,0,0,1,0,.09l0,.1-.25,0,0,.26a.89.89,0,0,1,0,.45,1.61,1.61,0,0,1-.14.38c0,.24,0,.42,0,.56s0,.26,0,.38a1.87,1.87,0,0,1-.08.34c0,.1-.08.21-.13.33a4.3,4.3,0,0,1-.21,1.63q-.26.74-.54,1.38l-.07.15a.53.53,0,0,0-.06.14A1.34,1.34,0,0,0,9.9,10l-.2.1a1.57,1.57,0,0,1-.25.14.86.86,0,0,1-.27,0A1.66,1.66,0,0,1,8.6,8.87,9.1,9.1,0,0,1,9,6.3a.15.15,0,0,0,.07-.17V6H8.87s0,0-.07.11c-.25.18-.52.36-.81.55s-.6.37-.91.53a6.71,6.71,0,0,1-1,.4,3.2,3.2,0,0,1-.94.16,1.9,1.9,0,0,1-1.77-1.2L3.3,6.34l-.11.19a1.66,1.66,0,0,0-.13.23C3,6.87,3,7,2.89,7.15a3.53,3.53,0,0,0-.15.46A.86.86,0,0,0,2.7,8a1.94,1.94,0,0,0-.28.29,1,1,0,0,0-.19.37.23.23,0,0,0,0,.11.56.56,0,0,0,0,.12A.16.16,0,0,1,2.2,9a.27.27,0,0,1-.08.07L2,9.22a.71.71,0,0,0-.1.14.83.83,0,0,0,0,.15.44.44,0,0,1,0,.13.26.26,0,0,1,0,.18,3.69,3.69,0,0,1-.2.4,1.07,1.07,0,0,1-.17.24.38.38,0,0,1-.17.12A.66.66,0,0,1,1.05,10.61Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M18.52,6.93a.82.82,0,0,0,0,.27,1.25,1.25,0,0,0,.09.25c0,.08.07.16.1.25A2.5,2.5,0,0,1,18.8,8a2,2,0,0,1,0,.35,2.34,2.34,0,0,1-.09.38,1.24,1.24,0,0,1-.15.33.49.49,0,0,1-.22.21,1.06,1.06,0,0,1-.4.33,2.44,2.44,0,0,0-.43.28.74.74,0,0,1-.29.12L16.9,10l-.31.15a3,3,0,0,1-.36.14,2.49,2.49,0,0,1-.37.09,1.32,1.32,0,0,1-.33,0,3.15,3.15,0,0,1-.69.17,2.4,2.4,0,0,1-.78,0,2,2,0,0,1-.78,0,1.67,1.67,0,0,1-.63-.3,2.24,2.24,0,0,1-.48-.49,2.52,2.52,0,0,1-.33-.6,3.08,3.08,0,0,1,0-1.22,4.2,4.2,0,0,1,.41-1,4.72,4.72,0,0,1,.6-.86l.66-.77a.11.11,0,0,0,.11,0,.27.27,0,0,0,.07-.08l.08-.1a.15.15,0,0,1,.1-.06.53.53,0,0,1,.24-.27.55.55,0,0,0,.25-.25.48.48,0,0,0,.18-.07l.13-.1.14-.12A.39.39,0,0,1,15,4.15V4a.77.77,0,0,0,.31-.22l.24-.3h.17l.2,0a1.08,1.08,0,0,1,.17-.25,1.12,1.12,0,0,1,.22-.17,1.58,1.58,0,0,0,.31.09.54.54,0,0,1,.3.15.3.3,0,0,0,.07.18.71.71,0,0,0,.13.12l.14.11a.39.39,0,0,1,.09.17.06.06,0,0,0,.06,0s0,0,.06,0a.59.59,0,0,0,.09.22.61.61,0,0,1,.09.21.85.85,0,0,0-.09.24.28.28,0,0,1-.14.18s0,.08,0,.09.07,0,.07.06a.17.17,0,0,1,0,.15A8.06,8.06,0,0,1,18,6,8.12,8.12,0,0,0,18.52,6.93ZM17.05,8a.53.53,0,0,1,.12-.21.44.44,0,0,0,.09-.25.59.59,0,0,1-.07-.14.81.81,0,0,1,0-.17,6.06,6.06,0,0,1-.45-.78,4.84,4.84,0,0,0-.42-.78.22.22,0,0,1-.08.09s-.08,0-.13,0a.2.2,0,0,1-.08.13.2.2,0,0,1-.13,0l-.15,0-.13-.07-.68.52a3.46,3.46,0,0,0-.61.6l-.06,0s0,0-.07,0a2,2,0,0,1-.23.4l-.26.37a1.94,1.94,0,0,0-.22.4,1.22,1.22,0,0,0-.11.49.87.87,0,0,0,.47.2,2.61,2.61,0,0,0,.56,0A3.43,3.43,0,0,0,15,8.79a3.14,3.14,0,0,1,.51,0,3.28,3.28,0,0,0,.84-.31Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M21.12,9.22a6,6,0,0,1-.23.71,2.45,2.45,0,0,1-.3.5.26.26,0,0,0-.21,0,.69.69,0,0,1-.13.11.19.19,0,0,1-.15,0L20,10.52l-.09-.06a.35.35,0,0,0-.16,0c0-.06,0-.12-.05-.18s-.08-.07-.16-.05a.47.47,0,0,0,0-.16l0-.12,0-.11a.31.31,0,0,1,0-.13s-.07,0-.09-.08a1.3,1.3,0,0,0-.08-.17A3.91,3.91,0,0,1,19.42,9a1.9,1.9,0,0,1,.09-.36c0-.11.06-.22.08-.32a.5.5,0,0,0,0-.34s.05,0,.07,0a.49.49,0,0,0,0-.14,1.1,1.1,0,0,0,0-.18.46.46,0,0,0,0-.14,5.1,5.1,0,0,0,.2-.63c.05-.22.1-.43.14-.64s.09-.42.13-.62a3.07,3.07,0,0,1,.19-.56.5.5,0,0,1,.29,0,.29.29,0,0,1,.19.13,2.94,2.94,0,0,0,.54-.57L21.82,4a3.24,3.24,0,0,1,.55-.56,1.3,1.3,0,0,1,.75-.29l.52.35a1.57,1.57,0,0,1,.48.48l.16.1.15.11v.14a1,1,0,0,1,.23.3,3,3,0,0,1,.16.36l.14.39c.05.13.1.25.16.37a.44.44,0,0,0,0,.1.31.31,0,0,0,0,.08l0,.1a.28.28,0,0,1,0,.13,1.33,1.33,0,0,1-.34.83,2.3,2.3,0,0,1-.72.52,6,6,0,0,1-.9.35,7.33,7.33,0,0,0-.87.3,0,0,0,0,0,.05.05s0,0,0,.06l.45.28a1.94,1.94,0,0,0,.31.17l.32.13.47.12.48.11.31.07.28.05.42,0a.94.94,0,0,1,.18.25.91.91,0,0,1,.1.29,1,1,0,0,1,0,.29.33.33,0,0,1-.08.21s0,0,.05,0h.09a.63.63,0,0,0-.12.11l-.09.15-.11.12a.24.24,0,0,1-.16.06,1.53,1.53,0,0,1-.91,0,9.48,9.48,0,0,1-1.4-.45l-.68-.29a3.87,3.87,0,0,1-.46-.24,2.48,2.48,0,0,1-.35-.27Zm1.67-4a1.88,1.88,0,0,1-.28.34c-.11.1-.21.21-.31.31a2.36,2.36,0,0,0-.26.36,1.14,1.14,0,0,0-.17.45,3.67,3.67,0,0,0,.86-.23l.84-.35a1,1,0,0,0-.25-.58A1.06,1.06,0,0,0,22.79,5.2Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M34,8.33a2,2,0,0,1-.74.78,5.09,5.09,0,0,1-1,.46c-.37.12-.76.23-1.16.32a9.32,9.32,0,0,0-1.17.36l-.28,0-.24,0-.21,0-.24,0a0,0,0,0,0-.06,0s0,0,0,0l-.36,0h-.26a1,1,0,0,0-.29,0l-.26,0a1.91,1.91,0,0,1-1.38-.63,2.68,2.68,0,0,1-.56-1.61s0-.07,0-.07l-.06,0a.6.6,0,0,1,0-.2.46.46,0,0,0,0-.21,7.81,7.81,0,0,1,.69-1.2,4.55,4.55,0,0,1,.93-1,1.85,1.85,0,0,0,.14-.14,1.34,1.34,0,0,1,.11-.14.34.34,0,0,1,.12-.12.49.49,0,0,1,.18-.08,2.87,2.87,0,0,1,.58-.51c.23-.16.47-.31.72-.46L30,3.72a3.73,3.73,0,0,0,.67-.49c.09,0,.15.06.19,0a.15.15,0,0,1,.13,0,.51.51,0,0,0,.23.25l.29.14a1.67,1.67,0,0,1,.28.18.84.84,0,0,1,.2.28c-.05,0-.07,0-.07.06l0,.1a.22.22,0,0,1,0,.11s0,.05-.09.05a.09.09,0,0,0,.08.07.05.05,0,0,1,.06.06.12.12,0,0,1,0,.09.13.13,0,0,0,0,.12,5.06,5.06,0,0,1-.52.29l-.6.3-.65.34a5.12,5.12,0,0,0-.65.42c-.21.16-.42.33-.64.52s-.43.39-.63.6-.4.43-.58.65a6.19,6.19,0,0,0-.46.67.28.28,0,0,0,.06.08.36.36,0,0,1,.05.09,4.09,4.09,0,0,0,.7,0,2.05,2.05,0,0,1,.65,0,.1.1,0,0,0,0-.07.11.11,0,0,1,.06-.07A9,9,0,0,0,30,8.42l1.17-.31L31,8l-.15,0s0-.07,0-.1A.31.31,0,0,0,31,7.8a.87.87,0,0,1-.27-.23.9.9,0,0,1-.12-.37.18.18,0,0,0,.07-.15.13.13,0,0,0-.07-.12h-.06s0,0,0,0,0,0,0,0h-.06a.48.48,0,0,1,.05-.18.77.77,0,0,0,.07-.16l.8-.54.32.2.39.22.43.23.4.21a1,1,0,0,1,.29.19l.25.24.24.24a1,1,0,0,0,.29.2.41.41,0,0,0,0,.25A.55.55,0,0,1,34,8.33Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M39.75,9.46a2.21,2.21,0,0,1-.21.4,1.34,1.34,0,0,1-.31.36,1.62,1.62,0,0,1-.41.27,1.21,1.21,0,0,1-.46.12.65.65,0,0,1-.21-.08L38,10.45l-.07,0,0,0-.14-.06,0-.15a.21.21,0,0,0,0-.14.88.88,0,0,0-.14-.15l-.07-.07a.19.19,0,0,1-.06-.07l-.2-.22.31-.09a.71.71,0,0,0,.4-.21.64.64,0,0,0,.07-.43.87.87,0,0,0-.39,0l-.32.08L37,9a2.29,2.29,0,0,1-.47,0h-.36l-.11,0c0-.08,0-.13,0-.14l0,0a1.11,1.11,0,0,1-.12-.16.35.35,0,0,1,0-.25.63.63,0,0,1-.07-.22,1.82,1.82,0,0,0-.11.3l0,.11-.12,0a.06.06,0,0,0,0,.05.25.25,0,0,0,0,.07l0,.19s0,0,0,0l-.05,0a.83.83,0,0,1-.06.21,1.59,1.59,0,0,1-.07.2c0,.1-.07.2-.1.3A1.33,1.33,0,0,0,35,10l0,.13a.33.33,0,0,1-.13.11l-.14.08-.07,0-.06,0,0,.13-.14,0-.18,0H34l-.2-.17-.16-.13a.51.51,0,0,1-.14-.34.26.26,0,0,1,0-.11.24.24,0,0,0,0-.07l-.15-.14.13-.11v-.2c.15-.4.32-.78.49-1.15L34.43,7l.18-.38.18-.37s0,0,0-.08a.43.43,0,0,1,0-.11A3.9,3.9,0,0,0,35,5.71c0-.1.1-.21.16-.32L35.37,5a3.52,3.52,0,0,1,.25-.38l.19-.08.08,0a.22.22,0,0,1,.11,0l.18,0a1.69,1.69,0,0,0,.57-.43,5.65,5.65,0,0,0,.5-.66l.12-.16.15.09a.54.54,0,0,0,.17.07l.22.06.4.11a.59.59,0,0,1,.3.17A1.66,1.66,0,0,1,38.7,4a.58.58,0,0,0,0,.19c0,.13.05.27.06.4s0,.28.05.44.07.27.1.4.05.26.07.39.08.37.12.54a3.27,3.27,0,0,0,.15.5h.12l.12,0,.15,0,.13,0,0,.12s0,.08.12.12a.49.49,0,0,1,.22.06l.13.11a.2.2,0,0,0,.1.07l.17.07-.09.18a1.4,1.4,0,0,1-.34.4,2,2,0,0,1-.48.33s0,.09,0,.13a.78.78,0,0,0,0,.14,2.7,2.7,0,0,1,.06.41,1.55,1.55,0,0,1,0,.43Zm-3.39-2,.64,0,.64-.1c0-.24-.11-.48-.18-.74s-.12-.5-.19-.75l-.07.12a4.31,4.31,0,0,0-.46.55,6.54,6.54,0,0,0-.36.57l-.15.21a2.25,2.25,0,0,0-.12.21h.25Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M42.93,8.31a.22.22,0,0,0-.16.12,1.8,1.8,0,0,0-.08.21l-.09.19a.12.12,0,0,1-.12.09.38.38,0,0,1-.06.16l-.1.15a.46.46,0,0,0-.07.16.32.32,0,0,0,0,.21.31.31,0,0,0-.12.2,2,2,0,0,1,0,.24.42.42,0,0,1-.1.2.28.28,0,0,1-.26.07s0,0,0,.06,0,0,0,.08a.31.31,0,0,0-.2,0,.25.25,0,0,1-.16,0,.82.82,0,0,1-.37-.24,2.26,2.26,0,0,1-.25-.4,4.14,4.14,0,0,1-.2-.43c-.06-.15-.12-.28-.17-.39a.53.53,0,0,0,0-.25.58.58,0,0,0-.08-.21c.38-.81.8-1.68,1.25-2.6s.88-1.83,1.29-2.73a5.05,5.05,0,0,1,.53.24,2.33,2.33,0,0,1,.48.31,1.74,1.74,0,0,1,.37.4,1.24,1.24,0,0,1,.2.51s0,.07,0,.06,0,0-.05,0a.21.21,0,0,1,.05.09v0s0,0,0,.07a.37.37,0,0,0,0,.11.08.08,0,0,0-.07,0s0,0-.07,0a.44.44,0,0,1,0,.21.53.53,0,0,1-.05.15.35.35,0,0,0-.06.14.25.25,0,0,0,0,.19.06.06,0,0,0-.05,0s0,0,0,0l0,0h-.06a2.19,2.19,0,0,1,0,.51c0,.17,0,.35,0,.52s0,.36,0,.55a2.54,2.54,0,0,0,.08.6,2.05,2.05,0,0,0,.67-.64,3.26,3.26,0,0,0,.41-.86,5.46,5.46,0,0,0,.24-1c.06-.36.12-.74.18-1.12a.76.76,0,0,0,.16-.26,3,3,0,0,0,.11-.32L46,3.51a1.43,1.43,0,0,1,.12-.31.57.57,0,0,1,.3.11,2.11,2.11,0,0,1,.23.19l.22.19a.76.76,0,0,0,.29.11c.1.13.18.26.26.38a1,1,0,0,1,.15.4.67.67,0,0,0-.09.21L47.45,5a1,1,0,0,1-.09.21.46.46,0,0,1-.17.17A4.1,4.1,0,0,1,47.1,6c0,.17-.07.33-.1.5a3.86,3.86,0,0,0-.09.51,3.58,3.58,0,0,0,0,.58c-.07.18-.13.37-.2.57s-.13.39-.17.55a1.28,1.28,0,0,0-.29.37c-.07.14-.14.28-.22.42s0,0-.06,0a.12.12,0,0,0-.08,0,.08.08,0,0,1,0,.07l0,0,0,0a.37.37,0,0,0,0,.09,1,1,0,0,0-.29.2,2.09,2.09,0,0,1-.28.24.74.74,0,0,1-.35.14,1.11,1.11,0,0,1-.53-.08,5,5,0,0,1-.85-.81A3.69,3.69,0,0,1,42.93,8.31Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M52,3.83c0-.06,0-.12,0-.17s-.05-.11-.07-.17a2.11,2.11,0,0,1-.1-.3,1.15,1.15,0,0,1,0-.39.34.34,0,0,1,.06-.17l.07-.09,0-.21h.57l.91,0,1-.08c.36,0,.75-.09,1.16-.11L55.59,2l0-.08.13-.08a.67.67,0,0,1,.37.07A.86.86,0,0,1,56.3,2L56.92,2l.65-.09,1-.11a7.66,7.66,0,0,1,.94-.06h.38l.34,0,.14.11a.83.83,0,0,0,.11.15l.17.14a2.85,2.85,0,0,1,.34.35.81.81,0,0,1,.13.63L61,3.22a.63.63,0,0,1-.12.1l-.12.15h-.13a.42.42,0,0,0-.17,0l-.14,0-.11,0-.13,0h-.36a5.18,5.18,0,0,0-.76.05l-.8.1-.54.07-.47,0q-.1.3-.21.54l-.2.47c-.07.15-.13.31-.2.47a5.52,5.52,0,0,0-.17.53c-.09.15-.17.28-.24.41a2.26,2.26,0,0,0-.15.44,1.34,1.34,0,0,0-.25.43c-.05.16-.1.29-.13.4l0,.18a.27.27,0,0,0-.1.13.26.26,0,0,1-.07.1,1.4,1.4,0,0,1-.09.29,1.12,1.12,0,0,1,0,.13.18.18,0,0,0,0,.1.52.52,0,0,1-.07.2.71.71,0,0,1,0,.14l0,.14a1,1,0,0,0,0,.14.28.28,0,0,0,0,.13l.07.09a.23.23,0,0,1,.06.12l.06,0,.06,0a.38.38,0,0,0,.18.09l.11.14a.75.75,0,0,1-.13.66.83.83,0,0,1-.7.27h-.16l-.1-.11-.17-.08a.27.27,0,0,1-.13-.11l-.06,0a.09.09,0,0,1-.06,0,.68.68,0,0,1-.19-.08l-.13-.07c0-.08,0-.13,0-.16a.85.85,0,0,1-.15-.28,1.92,1.92,0,0,1-.09-.32c0-.09,0-.16-.06-.23A.56.56,0,0,0,53.45,9a4,4,0,0,1,.15-1.38q.18-.56.39-1.08c.08-.19.15-.38.22-.58s.13-.39.19-.59l.13-.13.1-.09a.72.72,0,0,1,.06-.29c0-.1.08-.2.13-.3l.09-.19A.71.71,0,0,0,55,4.2h-.15l-.13,0-.29,0h-.16a.41.41,0,0,1-.17,0h-.45a.48.48,0,0,0-.22,0h-.6l-.21,0a.59.59,0,0,1-.24-.09l-.09-.09c0-.09-.09-.14-.13-.14L52,4Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M65.5,6.93a.82.82,0,0,0,0,.27,1.25,1.25,0,0,0,.09.25c0,.08.07.16.1.25a2.5,2.5,0,0,1,.07.26,2,2,0,0,1,0,.35c0,.13,0,.26-.08.38a1.26,1.26,0,0,1-.16.33.49.49,0,0,1-.22.21,1.06,1.06,0,0,1-.4.33,2.44,2.44,0,0,0-.43.28.74.74,0,0,1-.29.12l-.32.07-.31.15a3,3,0,0,1-.36.14,2.49,2.49,0,0,1-.37.09,1.32,1.32,0,0,1-.33,0,3.15,3.15,0,0,1-.69.17,2.4,2.4,0,0,1-.78,0,2,2,0,0,1-.78,0,1.75,1.75,0,0,1-.63-.3,2.24,2.24,0,0,1-.48-.49,2.52,2.52,0,0,1-.33-.6,3.08,3.08,0,0,1,0-1.22,4.2,4.2,0,0,1,.41-1,4.72,4.72,0,0,1,.6-.86l.66-.77a.11.11,0,0,0,.11,0,.27.27,0,0,0,.07-.08l.08-.1a.15.15,0,0,1,.1-.06.64.64,0,0,1,.24-.27.55.55,0,0,0,.25-.25.48.48,0,0,0,.18-.07l.13-.1.14-.12A.46.46,0,0,1,62,4.15V4a.77.77,0,0,0,.31-.22l.24-.3h.17l.2,0a1.52,1.52,0,0,1,.17-.25,1.12,1.12,0,0,1,.22-.17,1.5,1.5,0,0,0,.32.09.54.54,0,0,1,.29.15.38.38,0,0,0,.07.18.71.71,0,0,0,.13.12l.14.11a.39.39,0,0,1,.09.17.06.06,0,0,0,.06,0s0,0,.06,0a.59.59,0,0,0,.09.22.61.61,0,0,1,.09.21.85.85,0,0,0-.09.24.28.28,0,0,1-.14.18s0,.08,0,.09.07,0,.07.06a.17.17,0,0,1,0,.15A8.06,8.06,0,0,1,65,6,8.12,8.12,0,0,0,65.5,6.93ZM64,8a.53.53,0,0,1,.12-.21.38.38,0,0,0,.09-.25.59.59,0,0,1-.07-.14.81.81,0,0,1,0-.17,6.06,6.06,0,0,1-.45-.78,4.84,4.84,0,0,0-.42-.78.22.22,0,0,1-.08.09s-.08,0-.13,0a.2.2,0,0,1-.08.13.2.2,0,0,1-.13,0l-.15,0-.13-.07-.68.52a3.46,3.46,0,0,0-.61.6.1.1,0,0,1-.06,0s0,0-.07,0a2,2,0,0,1-.23.4l-.26.37a1.94,1.94,0,0,0-.22.4,1.22,1.22,0,0,0-.11.49.87.87,0,0,0,.47.2,2.61,2.61,0,0,0,.56,0,3.43,3.43,0,0,0,.56-.08,3.14,3.14,0,0,1,.51,0,3.28,3.28,0,0,0,.84-.31Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M74.32,10.23l-.22.11a.51.51,0,0,1-.22,0A1.39,1.39,0,0,1,73.4,9.2a7.65,7.65,0,0,1,.36-2.15s.06-.07.06-.14l-.11-.11h-.09a.11.11,0,0,0-.06.08c-.2.15-.43.3-.67.46a8,8,0,0,1-.76.45,4.14,4.14,0,0,1-.79.33,2.51,2.51,0,0,1-.79.13,1.58,1.58,0,0,1-1.48-1L69,7.09l-.1.15a1.17,1.17,0,0,0-.1.2,1.72,1.72,0,0,0-.14.33c0,.12-.09.25-.13.38a1.05,1.05,0,0,0,0,.33l-.22.25a.92.92,0,0,0-.18.32.25.25,0,0,0,0,.17.28.28,0,0,1-.11.17l-.1.11-.09.11a.5.5,0,0,0,0,.12s0,.07,0,.11a.41.41,0,0,1,0,.16,2.27,2.27,0,0,1-.31.53.44.44,0,0,1-.32.12l-.3,0a1.58,1.58,0,0,1-.28-.45,4.06,4.06,0,0,1-.19-.62,2.94,2.94,0,0,1,0-.66,1.46,1.46,0,0,1,.11-.58l.09-.24.24-.57.33-.78c.13-.28.25-.56.38-.83s.24-.52.36-.75.21-.39.28-.51a2.85,2.85,0,0,0,.17-.26l.15-.25a6.21,6.21,0,0,1,.4-.6,1.27,1.27,0,0,1,.57-.38.87.87,0,0,1,.38.25l0,.13a.16.16,0,0,0,.14.11l.22.07.19,0a.86.86,0,0,1,.15.22l.19.28a1.74,1.74,0,0,1,.14.27,1.31,1.31,0,0,1,.06.27.84.84,0,0,1-.06.3V5.2a.47.47,0,0,1,0,.3.91.91,0,0,0,0,.17.68.68,0,0,0,0,.19.64.64,0,0,0,.37.16.78.78,0,0,0,.35-.08,2.2,2.2,0,0,0,.3-.19l.19-.12a.43.43,0,0,1,.18-.06l.39-.36.35-.3a7.8,7.8,0,0,0,.68-.61,2.37,2.37,0,0,0,.53-.82.45.45,0,0,1,.25-.07.74.74,0,0,1,.27.06l.26.12.07,0,0,0a.23.23,0,0,0,.1.2s0,0,0,.05l0,0a.54.54,0,0,1,.22.19.71.71,0,0,0,.3.41.41.41,0,0,0,0,.22c0,.06,0,.13.08.2l0,.07,0,.09-.23,0,0,.23a1.17,1.17,0,0,1,0,.36,1.68,1.68,0,0,1-.12.31c0,.21,0,.37,0,.48a1.69,1.69,0,0,1,0,.32,2.13,2.13,0,0,1-.07.27,2,2,0,0,1-.11.28,3.56,3.56,0,0,1-.17,1.37c-.14.41-.29.79-.45,1.15l-.05.12-.06.12-.18.07Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M82.18,9.46a1.66,1.66,0,0,1-.2.4,1.59,1.59,0,0,1-.32.36,1.56,1.56,0,0,1-.4.27,1.31,1.31,0,0,1-.47.12.76.76,0,0,1-.21-.08l-.11-.08-.06,0,0,0-.15-.06.05-.15a.16.16,0,0,0,0-.14.6.6,0,0,0-.14-.15L80,9.81,80,9.74l-.2-.22.32-.09a.71.71,0,0,0,.39-.21.63.63,0,0,0,.08-.43.91.91,0,0,0-.4,0l-.32.08-.43.1A2.29,2.29,0,0,1,79,9H78.6l-.12,0c0-.08,0-.13,0-.14l0,0-.13-.16a.35.35,0,0,1,0-.25.94.94,0,0,1-.07-.22,1.76,1.76,0,0,0-.1.3l0,.11-.12,0a.11.11,0,0,0,0,.05.2.2,0,0,0,0,.07l-.06.19-.05,0s0,0,0,0l-.06.21a1.59,1.59,0,0,0-.07.2,1.76,1.76,0,0,0-.1.3.67.67,0,0,0,0,.28l0,.13a.33.33,0,0,1-.13.11l-.14.08-.06,0-.07,0,0,.13-.14,0-.19,0h-.24l-.19-.17a1.09,1.09,0,0,0-.17-.13.59.59,0,0,1-.12-.21.59.59,0,0,0,0-.13l0-.11a.08.08,0,0,0,0-.07l-.15-.14.13-.11v-.2c.16-.4.32-.78.5-1.15L76.86,7A3.26,3.26,0,0,0,77,6.63c.06-.12.12-.25.19-.37a.24.24,0,0,1,0-.08.23.23,0,0,1,0-.11l.21-.36c0-.1.11-.21.16-.32l.2-.39a2.47,2.47,0,0,1,.25-.38l.19-.08.08,0a.16.16,0,0,1,.1,0l.18,0a1.59,1.59,0,0,0,.57-.43,4.73,4.73,0,0,0,.51-.66l.11-.16.16.09a.48.48,0,0,0,.16.07l.22.06.4.11a.63.63,0,0,1,.31.17,2.83,2.83,0,0,1,.08.28l0,.19c0,.13,0,.27.07.4s0,.28,0,.44.07.27.1.4,0,.26.08.39.07.37.11.54a3.27,3.27,0,0,0,.15.5h.12l.13,0,.14,0,.13,0,0,.12a.2.2,0,0,0,.11.12.54.54,0,0,1,.23.06l.13.11a.18.18,0,0,0,.09.07l.18.07-.1.18a1.53,1.53,0,0,1-.33.4,2.34,2.34,0,0,1-.49.33.59.59,0,0,1,0,.13.39.39,0,0,0,0,.14l.06.41a3.08,3.08,0,0,1,0,.43Zm-3.38-2,.64,0c.19,0,.4-.06.63-.1C80,7,80,6.8,79.9,6.54s-.13-.5-.2-.75l-.07.12a3.58,3.58,0,0,0-.45.55,5,5,0,0,0-.37.57l-.14.21a2.39,2.39,0,0,0-.13.21h.26Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M82.91,5.17a.91.91,0,0,1,.26-.42,1.9,1.9,0,0,1,.4-.3L84,4.24l.45-.14c.31-.13.65-.26,1-.38s.72-.23,1.09-.32a5.42,5.42,0,0,1,1.14-.18,3.56,3.56,0,0,1,1.23.14,1,1,0,0,0,.11.23c0,.07.09.14.15.21l.13.2a.58.58,0,0,1,.07.21.83.83,0,0,1,.19.23.47.47,0,0,1,0,.32l-.23.17a1.36,1.36,0,0,1-.24.13A2.89,2.89,0,0,0,88.25,5l-.79.06a.35.35,0,0,0-.25-.1,1.15,1.15,0,0,0-.31,0l-.32.1a.72.72,0,0,1-.29,0,.69.69,0,0,1-.29.12l-.37.06a.06.06,0,0,0,0,.07s0,0,0,.08a.4.4,0,0,0,.19.11l.23.07.22.09a.33.33,0,0,1,.13.17.61.61,0,0,1,.3.08.81.81,0,0,1,.21.2.68.68,0,0,1,.12.26.79.79,0,0,1,0,.24c0,.06,0,.09.08.08s.06,0,.1,0a.92.92,0,0,0,.18.31,1.24,1.24,0,0,1,.22.34c0,.2,0,.37,0,.52a1.12,1.12,0,0,1-.09.47,2.49,2.49,0,0,1-.48.77,3.64,3.64,0,0,1-.7.6,4.87,4.87,0,0,1-.84.45c-.3.13-.59.23-.87.32h-.09s0,0,0-.06a.44.44,0,0,1-.2.11,1,1,0,0,1-.24,0h-.27a.69.69,0,0,0-.25,0l-.14-.16a.39.39,0,0,1-.1-.19.05.05,0,0,1-.06-.06s0-.06,0-.06A.55.55,0,0,0,83.4,10s0,0,0,0l0,0s0,0,0-.06a.36.36,0,0,0,0-.21c0-.08,0-.17,0-.25a.69.69,0,0,1,0-.25A.29.29,0,0,1,83.5,9a2.34,2.34,0,0,0,.75,0,3.83,3.83,0,0,0,.68-.21c.21-.1.41-.2.6-.31a5.1,5.1,0,0,1,.57-.29A.4.4,0,0,1,86.2,8l.13-.13a.8.8,0,0,0,.13-.13.3.3,0,0,0,.08-.17c0-.06,0-.09,0-.1s0,0,0-.09l-.13,0a2.52,2.52,0,0,0-.5-.22,2.82,2.82,0,0,1-.48-.19.35.35,0,0,1-.24,0l-.29-.07A6.27,6.27,0,0,1,84,6.81a2.9,2.9,0,0,1-.71-.21.24.24,0,0,0-.06-.11s-.06,0-.13,0L83,6.29a1.32,1.32,0,0,1-.12-.2l0-.24a.25.25,0,0,0-.07-.19l.07-.25A.48.48,0,0,0,82.91,5.17Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M90.77,9.62a.87.87,0,0,1,0,.55.68.68,0,0,1-.28.3.93.93,0,0,1-.41.11,2.2,2.2,0,0,1-.45,0,1.74,1.74,0,0,1-.21-.41l-.18-.51c0-.18-.1-.35-.14-.52S89,8.79,89,8.66A1.38,1.38,0,0,0,89,8.34c0-.12,0-.25,0-.37s0-.25,0-.38a2.22,2.22,0,0,1,0-.37,4.8,4.8,0,0,1,.24-.94,6.92,6.92,0,0,0,.25-.92.34.34,0,0,0,0-.2.29.29,0,0,1,0-.18.09.09,0,0,1,.07-.06s.07,0,.07-.08a.41.41,0,0,0,0-.21.31.31,0,0,1,0-.2,2.12,2.12,0,0,0,.15-.28A.62.62,0,0,0,90,3.77a1,1,0,0,0,.2-.16l.2-.17a1.18,1.18,0,0,1,.22-.14.85.85,0,0,1,.3,0A.58.58,0,0,1,91,3.5l.06.29a1.14,1.14,0,0,0,.09.26.37.37,0,0,0,.2.19v.24a2.44,2.44,0,0,0,0,.27A1,1,0,0,0,91.4,5a.22.22,0,0,0,.14.14c-.15.38-.28.75-.4,1.12a10.06,10.06,0,0,0-.3,1.11,8.42,8.42,0,0,0-.14,1.11A4.86,4.86,0,0,0,90.77,9.62Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M94.54,8.31a.2.2,0,0,0-.16.12l-.09.21a1.08,1.08,0,0,1-.08.19.13.13,0,0,1-.12.09.38.38,0,0,1-.06.16,1.3,1.3,0,0,0-.1.15.33.33,0,0,0-.07.16.32.32,0,0,0,0,.21.31.31,0,0,0-.12.2,2,2,0,0,1-.05.24.42.42,0,0,1-.1.2.28.28,0,0,1-.26.07s0,0,0,.06,0,0,0,.08a.3.3,0,0,0-.2,0,.25.25,0,0,1-.16,0,.82.82,0,0,1-.37-.24,2.26,2.26,0,0,1-.25-.4,4.14,4.14,0,0,1-.2-.43c-.06-.15-.12-.28-.17-.39a.71.71,0,0,0,0-.25A.58.58,0,0,0,92,8.6c.38-.81.8-1.68,1.25-2.6s.88-1.83,1.29-2.73a5.05,5.05,0,0,1,.53.24,2.33,2.33,0,0,1,.48.31,1.74,1.74,0,0,1,.37.4,1.39,1.39,0,0,1,.2.51s0,.07,0,.06,0,0,0,0a.21.21,0,0,1,0,.09v0S96,5,96,5a.37.37,0,0,0,0,.11.08.08,0,0,0-.07,0s0,0-.07,0a.44.44,0,0,1,0,.21.34.34,0,0,1-.06.15.58.58,0,0,0,0,.14.25.25,0,0,0,0,.19.11.11,0,0,0-.06,0,.05.05,0,0,0,0,0l0,0h-.06a2.91,2.91,0,0,1,0,.51c0,.17,0,.35,0,.52s0,.36,0,.55a2.54,2.54,0,0,0,.08.6,2.05,2.05,0,0,0,.67-.64,3.26,3.26,0,0,0,.41-.86,5.46,5.46,0,0,0,.24-1c.06-.36.11-.74.18-1.12a.76.76,0,0,0,.16-.26,3,3,0,0,0,.11-.32l.09-.34a1.43,1.43,0,0,1,.12-.31.6.6,0,0,1,.3.11l.23.19.22.19a.76.76,0,0,0,.29.11l.26.38a1,1,0,0,1,.15.4.67.67,0,0,0-.09.21L99.06,5a.68.68,0,0,1-.1.21.37.37,0,0,1-.16.17,4.1,4.1,0,0,1-.09.56c0,.17-.07.33-.1.5a3.86,3.86,0,0,0-.09.51,3.58,3.58,0,0,0,0,.58c-.07.18-.13.37-.2.57s-.13.39-.17.55a1.28,1.28,0,0,0-.29.37c-.07.14-.14.28-.22.42s-.05,0-.06,0a.12.12,0,0,0-.08,0v.07l0,0,0,0a.37.37,0,0,0,0,.09,1,1,0,0,0-.29.2,2.09,2.09,0,0,1-.28.24.74.74,0,0,1-.35.14,1.1,1.1,0,0,1-.53-.08,4.68,4.68,0,0,1-.85-.81A3.69,3.69,0,0,1,94.54,8.31Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
      <path
        d="M101.39,9.62a.87.87,0,0,1,0,.55.74.74,0,0,1-.28.3.93.93,0,0,1-.42.11,2.13,2.13,0,0,1-.44,0,1.74,1.74,0,0,1-.21-.41c-.07-.17-.12-.34-.18-.51l-.15-.52-.11-.46a1,1,0,0,0,.08-.32c0-.12,0-.25,0-.37s0-.25,0-.38l0-.37a6.8,6.8,0,0,1,.24-.94,6.94,6.94,0,0,0,.26-.92.34.34,0,0,0,0-.2.23.23,0,0,1,0-.18.09.09,0,0,1,.07-.06.11.11,0,0,0,.07-.08.56.56,0,0,0,0-.21.31.31,0,0,1,0-.2,2.12,2.12,0,0,0,.15-.28.7.7,0,0,0,.06-.38,1,1,0,0,0,.2-.16l.19-.17a1.28,1.28,0,0,1,.23-.14.84.84,0,0,1,.29,0,.5.5,0,0,1,.13.25l.06.29a1.12,1.12,0,0,0,.08.26.41.41,0,0,0,.2.19v.24c0,.09,0,.18,0,.27A1,1,0,0,0,102,5a.2.2,0,0,0,.13.14c-.14.38-.27.75-.39,1.12a10.06,10.06,0,0,0-.3,1.11,7,7,0,0,0-.15,1.11A4.87,4.87,0,0,0,101.39,9.62Z"
        transform="translate(-0.06 -1.62)"
        fill={fillColor}
      />
    </svg>
  );
};

export const ShortTypedName = withWidth()(ShortTypedNameComponent);
export const LongTypedName = withWidth()(LongTypedNameComponent);
